import api from '../utils/api'
import Fuse from 'fuse.js'

export default {
  get (categoryId) {
    return api.get(`specialities/${categoryId}`)
  },
  async getAll (params) {
    const items = await api.get('specialities', { params: params })
    if (params && params.search) {
      const fuse = new Fuse(items, {
        includeScore: true,
        threshold: 0.25,
        keys: ['name', 'nameEng'],
      })
      return fuse.search(params.search).map(({ item }) => item)
    }
    return items
  },
  update (categoryId, category) {
    return api.patch(`specialities/${categoryId}`, category)
  },
  create (category) {
    return api.post('specialities', category)
  },
  remove (categoryId) {
    return api.delete(`specialities/${categoryId}`)
  },
  createModel () {
    return {
      name: null,
      type: null,
      sort: 0,
      duration: 30,
      for_adults: true,
      for_kids: true,
      disclaimer: null
    }
  }
}
